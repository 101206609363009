import React, { useEffect, useState, createContext, useContext } from "react";
import axios from "axios";

// api url
const apiUrl = process.env.REACT_APP_API_URL;

const CartContext = createContext({info: [], count: 0, client_id: 0});

const CartProvider = ({ children, modules }) => {
    const [cartItems, setCartItems] = useState({info: [], count: 0, client_id: 0});   

    // Verifica se existe o modulo referente a ele instalado antes de requisitar qualquer endpoint dependente
    useEffect(() => {
        const pushCartItems = async () => {
            try{
                if(modules.filter((f) => f.nome === 'Loja Aice Brasil').length > 0)
                {
                    const getCartItems = await axios.get(`${apiUrl}/v1/getCartItems/?session=${localStorage.getItem('$s_ID')}`, {withCredentials: true});
                    if(getCartItems.request.status === 200) { 
                        setCartItems(prev => ({ ...prev, info: [...getCartItems.data.info], count: getCartItems.data.count,client_id: getCartItems.data.client_id }));
                    }
                }
            }
            catch(error) { console.log('Erro no cart context: ', error); }
        }

        pushCartItems(); 
    }, [modules]);

    return <CartContext.Provider value={{cartItems, setCartItems}}>
        {children}
    </CartContext.Provider>
}

export const useCart = () => {
    const { cartItems, setCartItems } = useContext(CartContext);

    return { cartItems, setCartItems }
}

export default CartProvider;