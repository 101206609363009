import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom';

// URL do servidor Socket.IO
const SOCKET_URL = process.env.REACT_APP_API_URL;

// Criando o contexto para o socket
const SocketContext = createContext(null);

// Hook para acessar o socket em qualquer lugar
export const useSocket = () => { return useContext(SocketContext); };

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const connectWS = async () => {
            if(location.pathname !==  '/login')
            {
                const socketIo = await io(SOCKET_URL, {
                    transports: ['websocket'],
                    withCredentials: true
                });
        
                // Quando o socket estiver pronto, definimos ele no estado
                setSocket(socketIo);
        
                if(socketIo) return () => { socketIo.disconnect(); };
            }
        }

        connectWS();
    }, [location.pathname]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};