import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

// api url
const apiUrl = process.env.REACT_APP_API_URL;

const LoginRouterCheck = ({ children }) => {
  const [online, setOnline] = useState(false); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pushIsOnline = async () => {
      const validation = localStorage.getItem('$s_ID') || null;
      if (validation)
      {
        const isOnline = await axios.get(`${apiUrl}/v1/checkOnline/?session=${validation}`, { withCredentials: true });
        setOnline(Number(isOnline.data.online) === 1);
      }
      else { setOnline(false); }
      setLoading(false);
    };

    pushIsOnline();
  }, []);

  if (loading) { return <div className='loadingModule'><CircularProgress size="3rem" /></div>;}
  if (online) { return <Navigate to="/" />; } else { return children; }

};

export default LoginRouterCheck;