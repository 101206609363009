import React from 'react';
import './warnings.css';
import useWarnings from './useWarnings';

// Modulo principal dos avisos.
const Warnings = () => {
    // Usando o hook para fechar a janela ao clicar no botão de fechar
    const { closeMsg } = useWarnings();
    
    // Renderer
    return (
        <div>
            <div className='background-options-msg' id='bg-options-msg' style={{display: 'none'}} />
            
            <div className='msg-box' id='msg-box'>
                <div className='container-msg-one'>
                    <div className='msg-box-content' id='msg-box-msg' />
                    <button className='msg-close-btn' id='msg-close-btn' onClick={closeMsg}><i className="fa-solid fa-xmark"></i></button>
                </div>

                <div className='container-msg-two' id='optoin-buttons-wrapper' style={{display: 'none'}}>
                    <button type='button' className='' onClick={()=> {closeMsg(); }} id='option-1'>recusar</button>
                    <button type='button' className='accept' id='option-warning-accept'>Aceitar</button>
                </div>
            </div>
        </div>
    );
};// --> 

export default Warnings;