import React, { useEffect, useState } from "react";
import './Home.css';
import { Alert, Snackbar, Typography } from "@mui/material";
import axios from "axios";
import ModuloAplicativo from "../../componentes/ModulesComponent/ModulesComponent";

// api url
const apiUrl = process.env.REACT_APP_API_URL;

// Módulo
const Home = () => {
    // simulando retorno do banco de dados, tabela de modulos instalados.
    const [modules, setModules] = useState([]);
    const [open, setOpenMessage] = useState(false);
    const [tagMessage, setTagMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [permissions, setPermissions] = useState({});
    document.title = 'AICE Brasil ERP :: Home';
  
    // Checando permissões 
    useEffect(() => {
      const getUserPermission = async () => {
        try{
          const getUserPermission = await axios.get(`${apiUrl}/v1/getUserPermission/?session=${localStorage.getItem('$s_ID')}`, { headers: {'Content-Type': 'application/json'}, withCredentials: true });
          if(getUserPermission.request.status === 200) { setPermissions(getUserPermission.data); }
        }
        catch (error) { console.error("Erro ao buscar módulos:", error); }
      }
  
      // Executando a função
      getUserPermission();
    }, []);

    // Fechando Snackbar
    const closeSnack = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpenMessage(false);
    };

    // Mostrando os modulos ja instalados no banco de dados.
    useEffect(() => {
        const pushListOfInstalledModules = async () => {
            // Puxando informações do banco de dados
            try{
                const listInstalledModules = await axios.get(`${apiUrl}/v1/listInstalledModules/?session=${localStorage.getItem('$s_ID')}`, { headers: {'Content-Type': 'application/json'}, withCredentials: true });
                if(listInstalledModules.request.status === 200) { setModules(listInstalledModules.data); }
            }
            catch (error) { 
                setTagMessage(`Ocorreu um erro técnico ao puxar informações sobre os modulos.`);
                setMessageType('error');
                setOpenMessage(true); 
                console.error("Erro ao buscar módulos:", error);
            }
        }

        // Executando a função
        pushListOfInstalledModules();
    }, []);

    return (
        <>
            <main>
                <Typography sx={{ fontSize: '20px', margin: '20px 0px 20px 25px'}}>Aplicativos</Typography>
                <section className="home-wrapper">
                    {modules.filter((f) => f.tipo === 'module').map((m) => (
                        <ModuloAplicativo key={m.id} module={m} permissions={permissions} setTagMessage={setTagMessage} setMessageType={setMessageType} setOpenMessage={setOpenMessage} type={'module_access'} firstPage={m.main_page} viewLinkName={m.nome_link}/> 
                    ))}
                </section>

                <Snackbar open={open} autoHideDuration={5000} onClose={closeSnack}>
                    <Alert
                        onClose={closeSnack}
                        severity={messageType}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {tagMessage}
                    </Alert>
                </Snackbar>
            </main>
        </>
    );
}

export default Home;