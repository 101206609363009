import { useState, useEffect } from 'react';
//import axios from 'axios';

// URl da API
//const apiUrl = process.env.REACT_APP_API_URL;

// Configurando o Hook
const UseWarnings = () => {
    const [msg] = useState(``);
    const [msgQueue, setMsgQueue] = useState([]);
    const [isDisplaying, setIsDisplaying] = useState(false); // Identificando se já esta mostrando uma mensagem

    // Função para adicionar a mensagem à fila
    const addToQueue = (message, model, type) => setMsgQueue((prevQueue) => [...prevQueue, { message, model, type }])

    // Função que exibe a mensagem
    const displayMessage = async (message, model, type) => {
        setIsDisplaying(true);
        
        let msgBox = document.getElementById('msg-box');
        let optionsBox = document.getElementById('optoin-buttons-wrapper');
        let msgboxBG = document.getElementById('bg-options-msg');

        document.querySelectorAll('#msg-box').forEach((cl) => {
            if(cl.classList.contains('warning')) cl.classList.remove('warning');
            if(cl.classList.contains('succeed')) cl.classList.remove('succeed');
            if(cl.classList.contains('error')) cl.classList.remove('error');
        });
        msgBox.classList.add(`${model}`);

        // Mudando mensagem no componente.
        document.getElementById('msg-box-msg').innerHTML = `<span className='text-msgbox'>${message}</span>`;

        // Controle de animações
        switch(type) {
            case 'message': {
                optionsBox.style.display = 'none';
                msgBox.style.animation = 'popupMsgBox .2s';
                await delay(170);
                msgBox.style.top = '5px';
                await delay(3000);
                msgBox.style.animation = 'popoutMsgBox .25s';
                await delay(220);
                msgBox.style.top = '-130px';
                break;
            }

            case 'prompt': {
                optionsBox.style.display = 'flex';
                msgboxBG.style.display = 'block';
                msgBox.style.animation = 'popupMsgBox .2s';
                await delay(170);
                msgBox.style.top = '5px';
                break;
            }

            // Não executa o componente
            default: break;
        }

        // Libera para exibir a próxima mensagem
        setIsDisplaying(false);
    };

    // Função para fechar a mensagem
    const closeMsg = async () => {
        let msgBox = document.getElementById('msg-box');
        let msgboxBG = document.getElementById('bg-options-msg');
        msgBox.style.animation = 'popoutMsgBox .25s';
        msgboxBG.style.display = 'none';
        await delay(220);
        msgBox.style.top = '-130px';
        setIsDisplaying(false);
    };

    // Delay function 
    const delay = (time) => { return new Promise(resolve => setTimeout(resolve, time)); }

    // Monitora a fila de mensagens e exibe a próxima quando possível
    useEffect(() => {
        if (!isDisplaying && msgQueue.length > 0)
        {
            const nextMsg = msgQueue[0];

            // Remove a mensagem exibida da fila
            displayMessage(nextMsg.message, nextMsg.model, nextMsg.type).then(() => setMsgQueue((prevQueue) => prevQueue.slice(1)));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [msgQueue, isDisplaying]);


    // Função para aceitar funções quando o modelo prompt é exibido
    const accept = async (option) => {  
        //options 
        switch(option)
        {
            case 0: {
                break;
            }

            default: break;
        }

        // Fechando a mensagem
        closeMsg();
    };


    // Returnning the functions for hooks
    return { msg, addToQueue , closeMsg, accept };
};

export default UseWarnings;