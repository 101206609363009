import React, { useEffect, useState } from "react";
import './ModulesComponent.css';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
//import MessageModal from "../MUI.MessageModal";

// api url
const apiUrl = process.env.REACT_APP_API_URL;

// Componente
const ModuloAplicativo = ({module, setModules, setTagMessage, setMessageType, setOpenMessage, type, firstPage, viewLinkName}) => {
    const [loading, setLoading] = useState(false);
    const [loadingUninstall, setLoadingUninstall] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const OpenOPTMenu = (event) => setAnchorEl(event.currentTarget);
    const CloseOPTMenu = () => setAnchorEl(null);
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState({});

    // Checando permissões 
    useEffect(() => {
        const getUserPermission = async () => {
        try{
            const getUserPermission = await axios.get(`${apiUrl}/v1/getUserPermission/?session=${localStorage.getItem('$s_ID')}`, { headers: {'Content-Type': 'application/json'}, withCredentials: true });
            if(getUserPermission.request.status === 200) { setPermissions(getUserPermission.data); }
        }
        catch (error) { console.error("Erro ao buscar módulos:", error); }
        }
    
        // Executando a função
        getUserPermission();
    }, []);

    // Instalando modulo
    const installModule = async (moduleName) => { 
        setLoading(true);

        // Tenta fazer a chamada com o token jwt fornecido pela sessão logada.
        try {
            const installModule = await axios.post(`${apiUrl}/v1/installERPModule/?session=${localStorage.getItem('$s_ID')}`, { module, user: (localStorage.getItem('isRememberChecked') === 'true') ? localStorage.getItem('username') : sessionStorage.getItem('username') },
            { headers: { 'Content-Type': 'application/json' }, withCredentials: true });

            // A api deve retornar um status 201 (Created) de volta pro navegador.
            if(installModule.request.status === 201) 
            { 
                setTagMessage(installModule.data.message);
                setMessageType('success');
                setOpenMessage(true); 
                setModules((prevModules) => prevModules.map((module) => module.name === moduleName ? { ...module, installed: true } : module )); 
                setLoading(false);
            }
        }
        catch(error) { 
            setTagMessage(`Erro ao instalar o modulo ${moduleName}.`);
            setMessageType('error');
            setOpenMessage(true); 
            console.log(error);
            setLoading(false);
        }
    };

    // Instalando modulo
    const uninstallModule = async (event, moduleName, forceUninstall) => { 
        setLoadingUninstall(true);

        // Tenta fazer a chamada com o token jwt fornecido pela sessão logada.
        try {
            const uninstallModule = await axios.post(`${apiUrl}/v1/uninstallERPModule/?session=${localStorage.getItem('$s_ID')}`, { moduleName, forceUninstall }, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
            if(uninstallModule.request.status === 200) 
            { 
                setTagMessage(uninstallModule.data.message);
                setMessageType('success');
                setOpenMessage(true); 
                setModules((prevModules) => prevModules.map((module) => module.name === moduleName ? { ...module, installed: false } : module )); 
                CloseOPTMenu(event);
                setLoadingUninstall(false);
            }
        }
        catch(error) { 
            setTagMessage(`Erro ao instalar o modulo ${moduleName}.`);
            setMessageType('error');
            setOpenMessage(true); 
            console.log(error);
            setLoadingUninstall(false);
        }
    };

    // Renderizando 
    return (
        <>
            {(type === 'module_access' && module.permissoes.includes(permissions.role)) ? (
                <div className="module-item">
                    <div className="module-photo">
                        <img src={`${module.img || module.icone}`} alt="" />
                    </div>

                    <div className="module-info">
                        <h3>{module.name || module.nome}</h3>
                        <span>{module.desc || module.descricao}</span>
                    </div>

                    {/* Acesssando o modulo */}
                    {(type === 'module_access') && ( <button type="button" onClick={() => { navigate(`/${localStorage.getItem('$s_inst')}/${viewLinkName}/${firstPage}`); }} className="module-installbtn"><Typography>Acessar</Typography></button> )}
                </div>
            ) : (type === 'module_install' && permissions.role === 'developer') ? (
            <div className="module-item">
                <div className="module-photo">
                    <img src={`${module.img || module.icone}`} alt="" />
                </div>

                <div className="module-info">
                    <h3>{module.name || module.nome}</h3>
                    <span>{module.desc || module.descricao}</span>
                </div>
                
                {(module.type === 'module' && !module.installed && type === 'module_install') && (
                    <button type="button" className="module-installbtn" onClick={() => {installModule(module.name);}} disabled={(module.installed || loading)}> 
                        {loading && (
                            <>
                                <CircularProgress size="1.2rem" color="inherit" sx={{marginRight: '10px'}}/>
                                <Typography>Instalando...</Typography>
                            </>
                        )}
                        {(!module.installed && !loading) && ( <Typography>instalar</Typography> )}  
                    </button>
                )}

                {/* Opções sobre módulo instalado */}
                {(type === 'module_install') && (
                <Box sx={{ flexGrow: 0, right: 2, position: 'absolute' }}>
                    <Tooltip title="Opções do modulo">
                        <IconButton onClick={OpenOPTMenu} aria-label="notifications" size="small">
                            <MoreVertIcon sx={{ color: 'black' }}/>
                        </IconButton>
                    </Tooltip>

                    <Menu
                        sx={{ mt: '45px', padding: '10px'}}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={(!loadingUninstall) ? CloseOPTMenu : null}
                    >
                        <MenuItem>
                            <Typography sx={{ textAlign: 'center' }}>Informações</Typography>
                        </MenuItem>
                        {(module.installed) && (
                            <MenuItem onClick={(e) => uninstallModule(e, module.name, module.force_uninstall)}>

                                {loadingUninstall ? (
                                    <>
                                        <CircularProgress size="1rem" color="inherit" sx={{marginRight: '10px'}}/>
                                        <Typography>Desinstalando...</Typography>
                                    </>
                                ) : (<Typography sx={{ textAlign: 'center' }}>Desinstalar</Typography>)}
                            </MenuItem>
                        )}
                    </Menu>
                </Box>
                )}
            </div>
            ) : (<></>)}
        </>
    )
}

export default ModuloAplicativo