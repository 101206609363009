import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
//import axios from 'axios';
import { CircularProgress } from '@mui/material';

// api url
//const apiUrl = process.env.REACT_APP_API_URL;

const RedirectInstance = ({toInstance}) => {
  const [redirect, setredirect] = useState(false); 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateInstance = async () => {
      //
      setredirect(true);
      setTimeout(() => {setLoading(false)}, 1000);
    };

    validateInstance();
  }, []);

  if (loading) { return <div className='loadingModule'><CircularProgress size="3rem" /></div>;}
  if (redirect) { return <Navigate to={`/${toInstance}/`} />; } else { return <Navigate to={`/login`} />; }

};

export default RedirectInstance;