import React, { useEffect, useState } from "react";
import './login.css';
import axios from "axios";
import { CircularProgress, Typography, Button } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

// api url
const apiUrl = process.env.REACT_APP_API_URL;

const Login = ({setInstance}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [open, setOpenMessage] = React.useState(false);
    const [tagMessage, setTagMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const navigate = useNavigate();
    document.title = 'Solleana ERP x AICE :: Login';
    
    const closeSnack = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpenMessage(false);
    };

    // Deixando o toggle de login marcado dependendo da ultima escolha feita
    useEffect(() => {
        if(localStorage.getItem('isRememberChecked') === 'true' || sessionStorage.getItem('isRememberChecked') === 'true'){ document.getElementById('toggle-login').checked = true; }
        else { document.getElementById('toggle-login').checked = false; }
    }, [])

    // Login de usuario.
    const requestAuth = async (e) => {
        e.preventDefault();
        setLoading(true);// Ativa o loading no botao de login.

        // Pegando ip do usuário.
        const response = await axios.get('https://ipinfo.io/json'); 
        if(response.request.status === 200) 
        {
            // Request de autenticação via token jwt.
            const login = await axios.post(`${apiUrl}/v1/auth`, { user: email.toLowerCase(), pass: password, ip: response.data.ip, keep_connected: document.getElementById('toggle-login').checked},
            { headers: {'Content-Type': 'application/json'}, withCredentials: true });

            // Retornou informações com sucesso
            if(login.request.status === 200)
            {
                // Checando a marcação de conexão
                localStorage.setItem('$s_ID', login.data.session);
                localStorage.setItem('$s_inst', login.data.instance);
                if(document.getElementById('toggle-login').checked)
                {
                    localStorage.setItem('username', login.data.user.full_name);
                    localStorage.setItem('logedin', login.data.logedin);
                    localStorage.setItem('ip', response.data.ip);
                    localStorage.setItem('isRememberChecked', true);

                    // Redirecionar para a página inicial
                    (!login.data.wasLogedIn) ? setTagMessage(`Seja bem vindo(a), ${localStorage.getItem('username')}[${response.data.ip}]`) :
                    setTagMessage(`Seja bem vindo(a), ${localStorage.getItem('username')}[${response.data.ip}], Nós deslogamos sua sessão anterior para sua segurança.`);
                    setMessageType('success');
                    setOpenMessage(true);
                    setTimeout(() => {navigate(`/`);}, 3000);
                }
                else
                {
                    // Gravando usuário, se esta logado, nome completo e foto de perfil
                    sessionStorage.setItem('username', login.data.user.full_name);
                    sessionStorage.setItem('logedin', login.data.logedin);
                    sessionStorage.setItem('ip', response.data.ip);
                    localStorage.setItem('isRememberChecked', false);

                    // Redirecionar para a página inicial
                    (!login.data.wasLogedIn) ? setTagMessage(`Seja bem vindo(a), ${sessionStorage.getItem('username')}[${response.data.ip}]`) :
                    setTagMessage(`Seja bem vindo(a), ${sessionStorage.getItem('username')}[${response.data.ip}], Nós deslogamos sua sessão anterior para sua segurança.`);
                    setMessageType('success');
                    setOpenMessage(true); 
                    setTimeout(() => {navigate(`/`);}, 3000);
                }
            }else { setTagMessage(`${login.data.message}`); setMessageType('error'); setOpenMessage(true); setLoading(false); }
        } else { setTagMessage(`Erro ao recuperar endereço de IP do usuário devido a falta de conexão com a internet.`); setMessageType('error'); setOpenMessage(true); setLoading(false); }
    };

    // Renderizando
    return (
        <>
            <main>
                <section className="main-frame">
                    <svg className="shapes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
                        <circle cx="200" cy="150" r="100" fill="rgba(255, 255, 255, 0.2)" />
                        <rect x="500" y="300" width="150" height="150" fill="rgba(255, 255, 255, 0.1)" transform="rotate(45 600 375)" />
                        <polygon points="400,50 450,150 350,150" fill="rgba(255, 255, 255, 0.15)" />
                    </svg>

                    <div className="login-wrapper">
                        <img src={`/imgs/new-logo.png`} alt="" width={235} style={{marginBottom: '20px'}}/>
                        <form onSubmit={requestAuth}>
                        <h1>Login</h1>
                            <label className='column-align'>
                                <span>Email :</span>
                                <i className="fa-solid fa-user"></i>
                                <input type='email' id='user' name='user' value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='exemplo@dominio.com.br'/>
                            </label>

                            <label className='column-align'>
                                <span>Senha :</span>
                                <i className="fa-solid fa-key"></i>
                                <input type='password' id='pass' name='pass' value={password} onChange={(e) => setPassword(e.target.value)} required maxLength={18} placeholder='Digite sua senha'/>
                            </label>

                            <label className='row-wrapper'>
                                <span style={{fontWeight: 600}}>Lembre de mim</span>
                                <div className="switch">
                                    <input type="checkbox" id="toggle-login" /> 
                                    <label htmlFor="toggle-login" className="slider"></label>
                                </div>
                            </label>

                            <Button type='submit' className='login-btn' disabled={loading}> {loading && <CircularProgress size="1.2rem" color="inherit" sx={{marginRight: '10px'}}/>} Entrar</Button>
                        </form>
                    </div>

                    <div className="copyright-section">
                        <Typography className="copy-span-title" sx={{ fontWeight: 600, color: 'black'}}>Sistema baseado no ERP chamado Solleana</Typography>
                        <Typography className="copy-span-desc" sx={{ fontWeight: 600, color: 'black'}}>CodedBytes x AICE &copy; All Rights Reserved.</Typography>
                    </div>

                    <Snackbar open={open} autoHideDuration={5000} onClose={closeSnack}>
                        <Alert
                            onClose={closeSnack}
                            severity={messageType}
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {tagMessage}
                        </Alert>
                    </Snackbar>
                </section>
            </main>
        </>
    )
}

export default Login;