import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// api url
const apiUrl = process.env.REACT_APP_API_URL;

const ResponsiveAppBar = ({addons}) => {
  // Estados
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [photo, setPhoto] = React.useState('');
  const OpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const OpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const CloseNavMenu = () => setAnchorElNav(null);
  const CloseUserMenu = () => setAnchorElUser(null);
  const navigate = useNavigate();

  // Fazendo o logout do usuário.
  const doLogout = async () => {
    try{
      const logout = await axios.get(`${apiUrl}/v1/logout-user/?session=${localStorage.getItem('$s_ID')}`, { withCredentials: true });
      if(logout.request.status === 200)
      { 
        localStorage.getItem('$s_ID'); 
        localStorage.getItem('$u_n');
        localStorage.getItem('$u_photo');
        sessionStorage.getItem('$s_ID'); 
        sessionStorage.getItem('$u_n');
        sessionStorage.getItem('$u_photo');

        navigate('/login');
      }
    }
    catch(error) { console.log(error); }
  };

  // Pegando foto do usuario
  React.useEffect(() => {
    const getPhoto = async () => {
      try {
          const profilePhoto = await axios.get(`${apiUrl}/v1/getUserPhoto/?session=${localStorage.getItem('$s_ID')}`, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
          if (profilePhoto.request.status === 200) { localStorage.setItem('photo', profilePhoto.data.profile_photo); setPhoto(profilePhoto.data.profile_photo); }
      }
      catch (error) { console.log(`Não foi possivel carregar a foto. Erro ${error}`); }
    };
    getPhoto();

    // Garantindo que se o storage miudar pro photo, ele retorno o do banco de dados.
    const StorageChange = () => setPhoto(localStorage.getItem('photo'));
    window.addEventListener('storage', StorageChange);

    // Desarmando 
    return () => { window.removeEventListener('storage', StorageChange); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl]);

  // Renderizando o componente.
  return (
    <>
    <AppBar position="static"  sx={{
          zIndex: (theme) => theme.zIndex.drawer +8, // Garantindo que o AppBar esteja acima de outros componentes,
          backgroundColor: '#410ba1'
        }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src='/imgs/logo.png' alt='' width={110}/>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="Conta do usuário"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={OpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={CloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem onClick={() => { navigate(`/`); }}>
                <Typography sx={{ textAlign: 'center' }}>Início</Typography>
              </MenuItem>

              <MenuItem onClick={() => { navigate(`/${localStorage.getItem('$s_inst')}/modules`); }}>
                <Typography sx={{ textAlign: 'center' }}>Módulos</Typography>
              </MenuItem>
              
              <MenuItem onClick={() => { navigate(`/${localStorage.getItem('$s_inst')}/settings`); }}>
                <Typography sx={{ textAlign: 'center' }}>Configurações</Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Tooltip title="Pagina inicial do ERP">
              <Button
                  onClick={() => { navigate(`/`); }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  Início
              </Button>
            </Tooltip>

            <Tooltip title="Lista de módulos a serem utilizados em seu ERP">
              <Button
                  onClick={() => { navigate(`/${localStorage.getItem('$s_inst')}/modules`); }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  Módulos
              </Button>
            </Tooltip>

            <Tooltip title="Configurações da sua instância ERP">
              <Button
                  onClick={() => { navigate(`/${localStorage.getItem('$s_inst')}/settings`); }}
                  sx={{ my: 2, color: 'white', display: 'block' }}
              >
                  Configurações
              </Button>
            </Tooltip>
          </Box>

          {addons.filter((f) => f.anchor === 'navbar').map(addon => (
            <div key={addon.id}>
              <React.Suspense fallback={<Skeleton sx={{ bgcolor: 'grey.600', width: 25, height: 25, marginRight: 3}} variant="circular" />}>
                <addon.Component />
              </React.Suspense>
            </div>
          ))}

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={`Perfil de ${(localStorage.getItem('isRememberChecked') === 'true') ? localStorage.getItem('username') : sessionStorage.getItem('username')}`}>
              <IconButton onClick={OpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={`${(localStorage.getItem('isRememberChecked') === 'true') ? localStorage.getItem('username') : sessionStorage.getItem('username')}`} src={`${apiUrl}/uploads/profiles/${photo}`} />
              </IconButton>
            </Tooltip>
            
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={CloseUserMenu}
            >
                <MenuItem onClick={CloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>Perfil</Typography>
                </MenuItem>
                <MenuItem onClick={CloseUserMenu}>
                  <Typography sx={{ textAlign: 'center' }}>Configurações</Typography>
                </MenuItem>
                <MenuItem onClick={doLogout}>
                  <Typography sx={{ textAlign: 'center' }}>Sair</Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </>
  );
}
export default ResponsiveAppBar;