import React, {useState} from "react";
import PropTypes from 'prop-types';
import './settingsPage.css';
import { Autocomplete, Box, Button, Link, Tab, Tabs, TextField, Typography } from "@mui/material";
import TabPanel from "./components/MUI.TabPainel";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => { return { id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`, }; }

// Módulo
const SettingsPage = () => {
    // States
    document.title = 'AICE Brasil ERP :: Configurações';
    const [value, setValue] = useState(0);
    const [email, setEmail] = useState('');

    // Função para gerar sugestões de dominios de e-mails
    const emailDomains = ["@gmail.com", "@hotmail.com", "@yahoo.com", "@outlook.com", "@icloud.com", "@aol.com"];
    const getEmailSuggestions = (input) => { return emailDomains.map((domain) => `${input}${domain}`); };
  
    const ChangeValue = (event, newValue) => { setValue(newValue); };

    
    // Renderizando
    return (
        <>
            <main>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'calc(100vh - 90px)', marginTop: 2 }} >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={ChangeValue}
                    aria-label="Vertical tab"
                    sx={{ borderRight: 1, borderColor: 'divider', width: 200 }}
                >
                    <Tab label="Configurações Gerais" {...a11yProps(0)} />
                </Tabs>
                
                <TabPanel value={value} index={0}>
                    <section className="users-painel">
                        <div className="adduser-section">
                            <Typography sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 1 }}>Usuários</Typography>
                            <form>
                                <Autocomplete
                                    freeSolo
                                    options={getEmailSuggestions(email)}
                                    inputValue={email}
                                    onInputChange={(event, newInputValue) => setEmail(newInputValue) }
                                    renderInput={(params) => ( <TextField {...params} label="Email" required /> )}
                                    renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                                    sx={{width: 300}}
                                />
                                
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ width: 100 }}
                                    color="primary"
                                    disabled={email.length === 0}
                                >
                                    Convidar
                                </Button>
                            </form>
                        </div>

                        <div className="manage-users">
                            <Typography>Pendentes: </Typography>
                            <Typography>Ativos: </Typography>
                            <Link href="#" sx={{margin: '10px 20px'}}>Gerenciar usuários</Link>
                        </div>
                    </section>

                    <section className="language-painel">
                        <div className="selectlang-section">
                            
                        </div>
                    </section>
                </TabPanel>
            </Box>
            </main>
        </>
    )
}

export default SettingsPage