import React from "react";
import './404.css';

const NotFoundPage = () => {
    return(
        <section className="nfound-wrapper">
            <img src="/imgs/bg.jpeg" alt="" />

            <div className="wrapper-404">
                <h2>404 Não Encontrado</h2>
                <span>Não encontramos esta pagina em nosso sistema</span>
                <span>Caso ache que isto se trate de um erro, comunique o departamento de T.I</span>

                <button type='button' onClick={() => {window.location.href='/';}}>Voltar</button>
            </div>
        </section>
    )
}

export default NotFoundPage;