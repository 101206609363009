import React, { useEffect, useState } from "react";
import './EnableModule.css';
import axios from "axios";
import ModuloAplicativo from "../../componentes/ModulesComponent/ModulesComponent";
import { Alert, Card, Skeleton, Snackbar, Typography } from "@mui/material";
//import MessageModal from "../../componentes/MUI.MessageModal";

// api url
const apiUrl = process.env.REACT_APP_API_URL;

// Modulo
const EnableModule = () => {
    const [open, setOpenMessage] = React.useState(false);
    const [tagMessage, setTagMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [modulesFolder, setModulesFolder] = useState([{id: 0},{id: 1},{id: 2},{id: 3}]); // Objetos dummy apenas para visual de loading.
    const [loadingModules, setLoadingModules] = useState(false);
    document.title = 'AICE Brasil ERP :: Módulos';

    // Fechando Snackbar
    const closeSnack = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpenMessage(false);
    };

    // Puxando modulos disponíveis no back-end pro front-end usar
    useEffect(() => {
        setLoadingModules(true);
        const getModules = async () => {
            try {
                const listModules = await axios.get(`${apiUrl}/v1/listAvailableModules/?session=${localStorage.getItem('$s_ID')}`, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
                if(listModules.request.status === 200)
                { 
                    setModulesFolder(listModules.data); 
                    const installedModules = [];

                    // Puxando lista de módulos já instalados
                    const listInstalledModules = await axios.get(`${apiUrl}/v1/listInstalledModules/?session=${localStorage.getItem('$s_ID')}`, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
                    if(listInstalledModules.request.status === 200)
                    {
                        await listInstalledModules.data.map((i) => installedModules.push(i.nome));
                        setModulesFolder((prevModules) => prevModules.map((module) => ({ ...module, installed: installedModules.includes(module.name), })));
                        setLoadingModules(false);
                    } else { setLoadingModules(false); }
                }
            }
            catch (error) { 
                setTagMessage(`Ocorreu um erro técnico ao puxar informações sobre os modulos.`);
                setMessageType('error');
                setOpenMessage(true); 
                console.error("Erro ao buscar módulos:", error);
            }
        };
    
        // Pegando modulos
        getModules(); 
    }, []);

    return (
        <>
            <main>
                <header className="options-header">
                    <Typography sx={{fontWeight: 600, color: 'grey', fontSize: '18px'}}>Aplicativos</Typography>

                    <div className="search-area">
                        <form>
                            <input type="text" name="modules_search" id="modules_search" placeholder="Pesquisar..." required/>
                            <button type="submit"></button>
                        </form>
                    </div>
                </header>

                <section className="modules-painel">
                    {modulesFolder.map((mod) => { 
                        if(loadingModules) return (
                            <Card key={mod.id} sx={{ width: 370, height: 150, margin: '10px', position: 'relative', display: 'flex', flexDirection: 'row', backgroundColor: 'grey.100' }}>
                                <Skeleton sx={{ bgcolor: 'grey.400', position: 'absolute', left: 0, top: 0,  width: 370, height: 150, borderRadius: '.4rem'}} variant="retangular" />
                                <Skeleton sx={{ bgcolor: 'grey.600', position: 'absolute', left: 12, top: 45, height: 50, width: 50}} variant="circular" />
                                <Skeleton sx={{ bgcolor: 'grey.600', position: 'absolute', left: 75, top: 30,  width: 260, borderRadius: '.4rem'}} variant="retangular"  />
                                <Skeleton sx={{ bgcolor: 'grey.500', position: 'absolute', left: 75, top: 55,  width: 230, borderRadius: '.4rem'}} variant="retangular"  />
                                <Skeleton sx={{ bgcolor: 'grey.500', position: 'absolute', left: 75, top: 77,  width: 230, borderRadius: '.4rem'}} variant="retangular" />
                                <Skeleton sx={{ bgcolor: 'grey.500', position: 'absolute', right: 10, bottom: 10,  width: 120, height: 30, borderRadius: '.4rem'}} variant="retangular" />
                            </Card>
                        )
                        else
                        return (<ModuloAplicativo key={mod.id} module={mod} setModules={setModulesFolder} setTagMessage={setTagMessage} setMessageType={setMessageType} setOpenMessage={setOpenMessage} type={'module_install'}/>) 
                    } )}
                </section>
            </main>

            
            <Snackbar open={open} autoHideDuration={5000} onClose={closeSnack}>
                <Alert
                    onClose={closeSnack}
                    severity={messageType}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {tagMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default EnableModule;